import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Row } from "react-bootstrap"
import Smarthings from "../components/cases/smarthings"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"

const CaseSmarthingsPage = ({ location }) => {
    const { t, i18n } = useTranslation("smarthings")
    return (
        <Layout lang={i18n.resolvedLanguage}>
            <div className="max-container-pages">
                <Seo title={t("title")} lang={i18n.resolvedLanguage} description={t("seo.description", { ns: 'common' })} />
                <Row>
                    <Smarthings previous={location.state?.from ?? '/'} ></Smarthings>
                </Row>
            </div>

        </Layout>
    )
}

export default CaseSmarthingsPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common","smarthings"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`