import React, { useState } from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import ItemsCarousel from 'react-items-carousel';
import ButtonContact from "../layout/buttoncontact";
import { CloseCase } from "./CloseCase";
import "./smarthings.scss";

import phoneCarousel1 from "../../images/cases/smarthings/phoneVersion/phoneCarousel1.png";
import phoneCarousel2 from "../../images/cases/smarthings/phoneVersion/phoneCarousel2.png";
import phoneCarousel3 from "../../images/cases/smarthings/phoneVersion/phoneCarousel3.png";
import phoneCarousel4 from "../../images/cases/smarthings/phoneVersion/phoneCarousel4.png";

import tabletCarousel1 from "../../images/cases/smarthings/tabletVersion/tabletCarousel1.png";
import tabletCarousel2 from "../../images/cases/smarthings/tabletVersion/tabletCarousel2.png";
import tabletCarousel3 from "../../images/cases/smarthings/tabletVersion/tabletCarousel3.png";

import { useTranslation } from "gatsby-plugin-react-i18next";
import watchCarousel1 from "../../images/cases/smarthings/watchVersion/watchCarousel1.png";
import watchCarousel2 from "../../images/cases/smarthings/watchVersion/watchCarousel2.png";
import watchCarousel3 from "../../images/cases/smarthings/watchVersion/watchCarousel3.png";
import watchCarousel4 from "../../images/cases/smarthings/watchVersion/watchCarousel4.png";


const Smarthings = ({ className, previous }) => {
    const [activeItemIndexPhone, setActiveItemIndexPhone] = useState(0);
    const [activeItemIndexTablet, setActiveItemIndexTablet] = useState(0);
    const [activeItemIndexWatch, setActiveItemIndexWatch] = useState(0);
    const chevronWidth = 100;
    const { t } = useTranslation("smarthings")
    return (
        <section className={(className ? className + " " : "") + "smarthings-case"}>
            <Container fluid >
                <div className="max-container-smart">
                    <CloseCase referrer={previous} />
                    <Row className="">
                        <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="bg-smarthings-case-xxl"></div>
                        </Col>
                        <Col className="hide-xxl" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="bg-smarthings-case"></div>
                        </Col>
                        <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="bg-smarthings-case-draw  case-draw-md hide-xxl"></div>
                            <div className="container-stars app-sticker-sm tell-project-smarthings-case">
                                <h5 className="mb-4">{t("End-to-End Smart IoT Platform Powered by Samsung")}</h5>
                                <a href="https://www.smartthings.com/" target='_blank' rel="noreferrer"><Button className="btn-success">{t("Learn More About SmartThings")}</Button></a>
                                <Row className="rating-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Col className="" xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <span className="span-uptext">54K Raitings</span><br></br>
                                        <span className="span-middletext">4.3</span> <br></br>
                                        <div className="stars"></div><br></br>
                                    </Col>
                                    <Col className="" xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <span className="span-uptext">Language</span><br></br>
                                        <span className="span-middletext">EN</span> <br></br>
                                        <span className="span-uptext">+72 More</span> <br></br>
                                    </Col>
                                    <Col className="" xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <span className="span-uptext">Chart</span><br></br>
                                        <span className="span-middletext">#17</span> <br></br>
                                        <span className="span-uptext">Lifestyle</span> <br></br>
                                    </Col>
                                    <Col className="store-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <a href="https://play.google.com/store/apps/details?id=com.samsung.android.oneconnect&hl=es_MX">  <div className="stores1"></div></a>
                                        <a href="https://apps.apple.com/mx/app/smartthings/id1222822904">  <div className="stores2"></div></a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="bg-smarthings-case-draw draw-sm"></div>
                            <p className="app-sticker-md tell-project-smarthings-case">
                                <h5 className="mb-4">{t("End-to-End Smart IoT Platform Powered by Samsung")}</h5>
                                <a href="https://www.smartthings.com/" target='_blank' rel="noreferrer"><Button className="btn-success text-nowrap">{t("Learn More About SmartThings")}</Button></a>
                                <Row className="rating-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Col className="" xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <span className="span-uptext">54K Raitings</span><br></br>
                                        <span className="span-middletext">4.3</span> <br></br>
                                        <div className="stars"></div><br></br>
                                    </Col>
                                    <Col className="" xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <span className="span-uptext">Language</span><br></br>
                                        <span className="span-middletext">EN</span> <br></br>
                                        <span className="span-uptext">+72 More</span> <br></br>
                                    </Col>
                                    <Col className="" xl={4} lg={4} md={4} sm={4} xs={4}>
                                        <span className="span-uptext">Chart</span><br></br>
                                        <span className="span-middletext">#17</span> <br></br>
                                        <span className="span-uptext">Lifestyle</span> <br></br>
                                    </Col>
                                    <Col className="store-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <a href="https://play.google.com/store/apps/details?id=com.samsung.android.oneconnect&hl=es_MX">  <div className="stores1"></div></a>
                                        <a href="https://apps.apple.com/mx/app/smartthings/id1222822904">  <div className="stores2"></div></a>
                                    </Col>
                                </Row>
                            </p>
                        </Col>
                        <Col className="about-md" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p className="max-about-md">
                                <h6 className="">{t("About SmartThings")}</h6>
                                {t("Over 8 years ago, Samsung decided to develop an entire ecosystem for connected objects")}<br></br><br></br>
                                {t("Samsung decided that their open data exchange platform would enable to connect any possible devices or applications")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="reverse-column about-sm">
                        <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p>
                                <h6 className="">{("About SmartThings")}</h6>
                                {t("Over 8 years ago, Samsung decided to develop an entire ecosystem for connected objects")}<br></br><br></br>
                                {t("Samsung decided that their open data exchange platform would enable to connect any possible devices or applications")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="reverse-column our-contribution">
                        <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p className="max-about-md">
                                <h6 className="">{t("Our Contribution")}</h6>
                                <h4 className="bullet-culture black-two">  {t("Front-end Software Development using JavaScript and React")}</h4>
                                <h4 className="bullet-culture black-two ">{t("Back-end Software Development using Java, Scala and Kotlin")}</h4>
                                <h4 className="bullet-culture black-two ">{t("QA Automation")}</h4>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="previews" xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="container-previews">
                                <div>
                                    <h6 className="">{t("Preview")}</h6>
                                    <h4>  {t("Phone Version")}</h4>
                                    <div className="phoneCarousel">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexPhone}
                                            activeItemIndex={activeItemIndexPhone}
                                            numberOfCards={1}
                                            gutter={50}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={true}
                                            activePosition={'center'}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrowPhone', leftChevronWrapper: 'leftArrowPhone', itemsInnerWrapper: 'allItemsPhone' }}
                                        >
                                            <img alt="carousel item for smart things project" src={phoneCarousel1} className="smartThingsPhone1"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel2} className="smartThingsPhone2"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel3} className="smartThingsPhone3"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel4} className="smartThingsPhone4"></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="lilTabletCarousel">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexPhone}
                                            activeItemIndex={activeItemIndexPhone}
                                            numberOfCards={2}
                                            slidesToScroll={2}
                                            gutter={10}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={phoneCarousel1} className="smartThingsPhone1"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel2} className="smartThingsPhone2"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel3} className="smartThingsPhone3"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel4} className="smartThingsPhone4"></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="tabletCarousel">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexPhone}
                                            activeItemIndex={activeItemIndexPhone}
                                            numberOfCards={3}
                                            gutter={2}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={phoneCarousel1} className="smartThingsPhone1"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel2} className="smartThingsPhone2"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel3} className="smartThingsPhone3"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel4} className="smartThingsPhone4"></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="laptopCarousel">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexPhone}
                                            activeItemIndex={activeItemIndexPhone}
                                            numberOfCards={4}
                                            gutter={10}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={true}
                                            alwaysShowChevrons={false}
                                            chevronWidth={chevronWidth}
                                            infiniteLoop={false}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={phoneCarousel1} className="smartThingsPhone1"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel2} className="smartThingsPhone2"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel3} className="smartThingsPhone3"></img>
                                            <img alt="carousel item for smart things project" src={phoneCarousel4} className="smartThingsPhone4"></img>
                                        </ItemsCarousel>
                                    </div>
                                </div>
                                <div>
                                    <h4> {t("Tablet Version")}</h4>
                                    <div className="phoneCarouselTablet">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexTablet}
                                            activeItemIndex={activeItemIndexTablet}
                                            numberOfCards={1}
                                            gutter={50}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={true}
                                            activePosition={'center'}
                                            chevronWidth={chevronWidth}
                                            classes={{ itemsInnerWrapper: 'allItemsLilTablet' }}
                                        >
                                            <img alt="carousel item for smart things project" src={tabletCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={tabletCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={tabletCarousel3}></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="lilTabletCarouselTablet">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexTablet}
                                            activeItemIndex={activeItemIndexTablet}
                                            numberOfCards={2}
                                            slidesToScroll={2}
                                            gutter={10}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={tabletCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={tabletCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={tabletCarousel3}></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="tabletCarouselTablet">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexTablet}
                                            activeItemIndex={activeItemIndexTablet}
                                            numberOfCards={3}
                                            gutter={2}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={tabletCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={tabletCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={tabletCarousel3}></img>
                                        </ItemsCarousel>
                                    </div>
                                </div>
                                <div>
                                    <h4> {t("Watch Version")}</h4>
                                    <div className="watchCarouselPhone">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexWatch}
                                            activeItemIndex={activeItemIndexWatch}
                                            numberOfCards={1}
                                            gutter={10}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ itemsInnerWrapper: 'allItemsWatchPhone' }}
                                        >
                                            <img alt="carousel item for smart things project" src={watchCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel3}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel4}></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="watchCarouselTablet">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexWatch}
                                            activeItemIndex={activeItemIndexWatch}
                                            numberOfCards={2}
                                            gutter={10}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={watchCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel3}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel4}></img>
                                        </ItemsCarousel>
                                    </div>
                                    <div className="watchCarouselTablet2">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexWatch}
                                            activeItemIndex={activeItemIndexWatch}
                                            numberOfCards={3}
                                            gutter={2}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={watchCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel3}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel4}></img>
                                        </ItemsCarousel>
                                    </div>
                                </div>
                                <div>
                                    <div className="watchCarouselLaptop">
                                        <ItemsCarousel
                                            requestToChangeActive={setActiveItemIndexWatch}
                                            activeItemIndex={activeItemIndexWatch}
                                            numberOfCards={4}
                                            gutter={10}
                                            leftChevron={<i className="bi bi-chevron-left" />}
                                            rightChevron={<i className="bi bi-chevron-right" />}
                                            outsideChevron={false}
                                            chevronWidth={chevronWidth}
                                            classes={{ rightChevronWrapper: 'rightArrow', leftChevronWrapper: 'leftArrow' }}
                                        >
                                            <img alt="carousel item for smart things project" src={watchCarousel1}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel2}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel3}></img>
                                            <img alt="carousel item for smart things project" src={watchCarousel4}></img>
                                        </ItemsCarousel>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="contactus-case" xl={8} lg={7} md={6} sm={12} xs={12}>
                            <p>
                                <h3 className="">{t("Got a Project or Partnership in Mind")}</h3>
                                <ButtonContact title={t("Contact Us")}>{t("Contact Us")} 	<div className="Polygon-9"></div></ButtonContact>
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section >
    )
}

export default Smarthings